import React from "react";
import { Link } from "gatsby";

import logo from "../img/logo-ibbppta.svg";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import youtube from "../img/social/youtube.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className='footer has-background-black has-text-white-ter'>
        <div className='content has-text-centered'>
          <img
            src={logo}
            alt='IBBPPTA'
            style={{ width: "14em", height: "10em" }}
          />{" "}
        </div>{" "}
        <div className='content has-text-centered has-background-black has-text-white-ter'>
          <div className='container has-background-black has-text-white-ter'>
            <div style={{ maxWidth: "100vw" }} className='columns'>
              <div className='column is-4'>
                <section className='menu'>
                  <ul className='menu-list'>
                    <li>
                      <Link to='/' className='navbar-item'>
                        Home{" "}
                      </Link>{" "}
                    </li>{" "}
                    <li>
                      <Link className='navbar-item' to='/about'>
                        Sobre{" "}
                      </Link>{" "}
                    </li>{" "}
                    <li>
                      <Link className='navbar-item' to='/live'>
                        Live{" "}
                      </Link>{" "}
                    </li>{" "}
                    <li>
                      <a
                        className='navbar-item'
                        href='/admin/'
                        target='_blank'
                        rel='noopener noreferrer'>
                        Admin{" "}
                      </a>{" "}
                    </li>{" "}
                  </ul>{" "}
                </section>{" "}
              </div>{" "}
              <div className='column is-4'>
                <section>
                  <ul className='menu-list'>
                    <li>
                      <Link className='navbar-item' to='/blog'>
                        Ultímas Noticias{" "}
                      </Link>{" "}
                    </li>{" "}
                    <li>
                      <Link className='navbar-item' to='/contact'>
                        Contato{" "}
                      </Link>{" "}
                    </li>{" "}
                  </ul>{" "}
                </section>{" "}
              </div>{" "}
              <div className='column is-4 social'>
                <a
                  title='youtube'
                  href='https://www.youtube.com/c/ibbppta'
                  target='_blank'>
                  <img
                    src={youtube}
                    alt='Youtube'
                    style={{ width: "1em", height: "1em" }}
                  />{" "}
                </a>{" "}
                <a
                  title='facebook'
                  href='https://facebook.com/ibbppta'
                  target='_blank'>
                  <img
                    src={facebook}
                    alt='Facebook'
                    style={{ width: "1em", height: "1em" }}
                  />{" "}
                </a>{" "}
                <a
                  title='instagram'
                  href='https://instagram.com/ibbppta'
                  target='_blank'>
                  <img
                    src={instagram}
                    alt='Instagram'
                    style={{ width: "1em", height: "1em" }}
                  />{" "}
                </a>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </footer>
    );
  }
};

export default Footer;
